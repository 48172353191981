<template>
  <v-dialog
    v-model="display"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-clone-field-dialog>
      <DialogTitle @close="$emit('close')" :title="`Clone ${field.label}`" />
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dense
                aut-field-label
                label="Label"
                v-model="fieldDefinition.label"
                required
                :rules="labelRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                aut-field-name
                label="Name"
                v-model="fieldDefinition.name"
                required
                :rules="nameRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-clone-field-cancel>
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          @click="updateDefinition"
          aut-action-clone-field
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
import { clone, rules } from "@/util";
const debug = require("debug")("atman.components.clone_field_dialog");
debug(`clone_field_dialog`);
export default {
  name: "CloneFieldDialog",
  mixins: [dialogMixin],
  data() {
    const labels = [];
    const names = [];
    let fields =
      this.type == "field"
        ? this?.definition?.definition?.fields
        : this?.definition?.definition?.actions;
    fields.forEach(({ label, name }) => {
      labels.push(label);
      names.push(name);
    });
    return {
      valid: false,
      display: true,
      fieldDefinition: clone(this.field),
      labelRules: [
        rules.mandatory(),
        (val) => !labels.includes(val) || "This label has already been used",
      ],
      nameRules: [
        rules.mandatory(),
        (val) => !names.includes(val) || "This name has already been used",
      ],
    };
  },
  props: {
    field: {
      type: Object,
    },
    type: {
      type: String,
    },
    definition: {
      type: Object,
    },
  },
  mounted() {
    debug(`In CloneFieldDialog`, this.field, this.type, this.definition);
  },
  methods: {
    updateDefinition() {
      const formDefinition = clone(this.definition);
      if (this.type == "field") {
        formDefinition.definition.fields.push(this.fieldDefinition);
      } else {
        formDefinition.definition.actions.push(this.fieldDefinition);
      }
      debug(`in updateDefinition of CloneFieldDialog`, formDefinition);
      this.$emit("update:definition", formDefinition);
      this.$emit("close");
    },
  },
};
</script>
